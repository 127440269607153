<template>
  <div class="row pt-1" v-if="berechtigungen.m_bildung.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>ToR</span>
          </div>
          <div class="body">
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'tor-liste' }">
                    <div class="tile bg-light-grey-lila inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-file-certificate"
                          transform="left-1 shrink-2"
                        />
                      </div>
                      <div class="title">ToR</div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                @click="oeffneMessageBox()"
                class="cursor-pointer"
                :class="kachelKleinCol"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <div class="tile bg-light-grey-lila inverted">
                    <div class="icon-container fa-container">
                      <font-awesome-icon
                        icon="fa-duotone fa-book-arrow-up"
                        transform="shrink-3"
                      />
                    </div>
                    <div class="title">Noten übernehmen</div>
                  </div>
                </div>
              </div>
              <div :class="kachelKleinCol">
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link
                    :to="{
                      name: 'tor-abschluss-simulieren',
                    }"
                  >
                    <div class="tile bg-light-grey-lila inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-diploma"
                          transform="left-2 shrink-3"
                        />
                      </div>
                      <div class="title">Abschlüsse simulieren</div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <messagebox-enhanced
      headerText="Noten übernehmen"
      id="noten-uebernehmen-msgbox"
      :ok="true"
      :cancel="true"
      okText="Noten übernehmen"
      :cancelText="$t('global.cancel')"
      @ok="notenUebernehmen()"
    />
  </div>
</template>
	
<script>
import Tor from "@/Tor";
import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";
import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  name: "StartseiteTor",
  components: { MessageboxEnhanced },
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    oeffneMessageBox() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "noten-uebernehmen-msgbox",
        text: "Möchten sie wirklich alle Noten aus den Noteneingaben ins ToR übernehmen?",
      });
    },
    notenUebernehmen() {
      this.$emit("loading");
      Tor.post("grades/", {})
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Noten erfolgreich übernommen.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },
  },
};
</script>
	