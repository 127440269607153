<template>
  <div v-if="berechtigungen.m_lead.read">
    <div class="row pt-1">
      <div class="col-lg-12">
        <div class="tile-categorie-frame">
          <div class="tile-categorie">
            <div class="header">
              <div class="row">
                <div class="col-xl-6">
                  <span>{{ $t("dashboard.mytodo") }}</span>
                </div>
                <div class="col-xl-6 text-right pr-2">
                  <font-awesome-icon
                    icon="fa-solid fa-plus-circle"
                    style="cursor: pointer"
                    @click="neueAktivitaet"
                  />
                </div>
              </div>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-xl-12 block belegliste br-t-l-0">
                  <b-table
                    ref="todoTable"
                    tbody-tr-class="item"
                    style="height: 285px"
                    responsive
                    sticky-header
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    :busy="pendenzenIsBusy"
                    show-empty
                    :items="aktivitaeten"
                    :fields="fields"
                    @row-dblclicked="aktivitaetOeffnen"
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>

                    <template #empty>
                      <div
                        v-if="pendenzenLadeFehler"
                        class="text-center text-danger my-2"
                      >
                        <strong>{{ $t("global.errorwhileloading") }}</strong>
                      </div>
                      <div
                        v-if="!pendenzenLadeFehler"
                        class="text-center text-primary my-2"
                      >
                        <strong>{{
                          $t("dashboard.therearenopendingtodos")
                        }}</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <aktivitaet-modal
      :angemeldeterMitarbeiter="angemeldeterMitarbeiter"
      @aktivitaetCreated="aktivitaetErstellt"
      @aktivitaetChanged="aktivitaetGeaendert"
      @aktivitaetDeleted="aktivitaetDeleted"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";

import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import AktivitaetModal from "@/components/crm/AktivitaetModal";

export default {
  name: "StartseitePendenzen",
  components: { BSpinner, AktivitaetModal },
  store,
  mixins: [],
  props: {
    angemeldeterMitarbeiter: {
      type: Object,
    },
  },
  data() {
    return {
      pendenzenIsBusy: false,
      pendenzenLadeFehler: false,

      aktivitaeten: [],
      sortBy: "geplantdatum",
      sortDesc: false,
    };
  },
  created() {
    if (this.angemeldeterMitarbeiter) {
      this.getAktivitaeten();
    }
  },
  mounted() {},
  watch: {
    angemeldeterMitarbeiter: function (val) {
      if (val) {
        this.getAktivitaeten();
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "geplantdatum",
          sortable: true,
          label: this.$t("dashboard.planneddate"),
          formatter: (value, key, item) => {
            return value ? value + " - " + item.geplantzeit : "-";
          },
        },

        {
          key: "typ.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.type"),
        },

        {
          key: "firma.name",
          sortable: true,
          label: this.$t("dashboard.company"),
        },

        {
          key: "person.personName",
          sortable: true,
          label: this.$t("dashboard.contact"),
        },

        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("dashboard.topic"),
        },
      ];
    },
  },
  methods: {
    getAktivitaeten() {
      this.pendenzenIsBusy = true;
      Api.get("crm/aktivitaet/", {
        params: {
          resp: this.angemeldeterMitarbeiter.person.id,
          status: this.$CONST("CONFIG").AKTIVITAETSTATUSGEPLANT,
        },
      })
        .then((response) => {
          this.aktivitaeten = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.pendenzenLadeFehler = true;
        })
        .finally(() => {
          this.pendenzenIsBusy = false;
        });
    },

    neueAktivitaet() {
      let json = {
        verantwortlicher: [this.angemeldeterMitarbeiter.person],
        //verantwortlicher: this.$store?.state?.mitarbeiter?.person,
        person: this.ansprechpartner,
        neu: true,
      };
      this.$bus.$emit("aktivitaet-modal-show", json);
    },

    aktivitaetOeffnen(aktivitaet) {
      this.$bus.$emit("aktivitaet-modal-show", aktivitaet);
    },

    aktivitaetDeleted(aktivitaet) {
      Api.delete("crm/aktivitaet/", {
        params: { ids: aktivitaet.id },
      }).then(() => {
        this.getAktivitaeten();
      });
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.activitydeleted"),
      });
    },
    aktivitaetErstellt(aktivitaet) {
      console.log("aktivitaetErstellt");

      let json = aktivitaet;
      json.lead = this.lead;

      Api.post("crm/aktivitaet/", json)
        .then(() => {
          this.getAktivitaeten();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.activitysavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    aktivitaetGeaendert(aktivitaet) {
      console.log("aktivitaetGeaendert");

      let json = aktivitaet;
      json.lead = this.lead;

      Api.put("crm/aktivitaet/", json, { params: { id: aktivitaet.id } })
        .then(() => {
          this.getAktivitaeten();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.activitysavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sortDates(datumA, datumB, key) {
      if (key != "date") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
